$primary: #0E3F75;
$secondary: #D21437;

$font-family-sans-serif: 'BuckeyeSans', 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif;

@import '~bootstrap/scss/bootstrap.scss';

:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px white, 0 0 0 6px black !important;
}

@media (min-width: 992px) {
  .header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .app-container {
    padding-top: 72px;
  }
}

.header-cap {
  background-color: $white;
  .graphicElementGradient {
    background-image: linear-gradient(to right, $primary 90%, $dark 95%);
  }
}

.header {
  .navbar-nav a.nav-link {
    &.active {
      text-decoration: underline;
    }
  }
}

.login-panel.login-panel {
  padding: 0;
  .login-col-img {
    img {
      object-fit: cover;
      border-radius: 0.8rem;
      min-height: 100%;
      max-height: 350px;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .login-logo-img {
    display: block;
    margin: auto;
    margin-top: 2rem;
    max-height: 200px;
    width: 60%;
  }
}

.title-toolbar.title-toolbar {
  margin-bottom: 2rem;
  .col-navbar {
    .navbar {
      background-color: $primary;
      .navbar-brand {
        padding-bottom: 0;
        margin-top: 2px;
      }
      .dropdown-toggle {
        font-size: 1.25rem;
      }
      .btn:not(.dropdown-item) {
        color: $white;
        padding: 0 8px;
      }
    }
  }
  .logo {
    width: 100%;
    background-color: $white;
    max-width: 260px;
    min-width: 112px;
  }
}

.story-grid.story-grid {
  .rdw-editor-main {
    height: 350px;
    overflow-y: auto;
    .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
      height: unset;
    }
  }
}

.story-panel {
  position: relative;
  .btn-link {
    text-decoration: underline;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .slider-content {
    .files {
      max-height: calc(100vh - 160px);
      overflow-y: auto;
    }
  }
}

.rdw-link-decorator-wrapper {
  a, span {
    color: $primary;
  }
  .rdw-link-decorator-icon {
    display: none;
  }
}

.document-panel.document-panel {
  .action-bar {
    position: sticky;
    top: 72px;
  }
}

.rendered-file-browser.rendered-file-browser {
  .files table {
    margin-bottom: 0;
  }
  a, .item-actions label {
    cursor: pointer;
    color: $dark;
  }
}